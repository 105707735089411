<template>
  <div class="gmv-home">
    <b-row align-h="center" style="margin-right: 0px">
      <b-col sm="12" md="12" lg="10">
        <el-card class="box-card">
          <div class="grid">
            <b-row>
              <div class="title">
                <i
                  class="fas fa-university fa-2x"
                  style="color: #d32a2d; margin-bottom: 10px"
                ></i>
                <h3>Seleccione un <b style="color: #d32a2d">cliente</b></h3>
              </div>
            </b-row>
            <Loading v-if="isLoading" />
            <div class="cards-banks" v-if="!isLoading">
              <div
                v-for="bank in userBanks"
                :key="bank.id"
                @click="goToBank(bank.name)"
              >
                <b-container>
                  <b-row class="option-bank">
                    <b-col sm="12" md="12" lg="12">
                      <b-card
                        img-width="200"
                        img-alt="Card image"
                        img-left
                        class="mb-12 card-item"
                        style="border-radius: 1rem"
                      >
                        <img
                          :alt="bank.name + 'logo'"
                          :src="bank.img"
                          width="250"
                        />
                        <b-card-text>
                          <div style="float: right">
                            <i
                              class="fas fa-arrow-circle-right fa-3x"
                              style="
                                color: #d32a2d;
                                margin-right: 10px;
                                vertical-align: -1em;
                              "
                            ></i>
                          </div>
                        </b-card-text>
                        <p class="bank-card__title">{{ bank.name }}</p>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
            </div>
          </div>
        </el-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BContainer } from "bootstrap-vue";
import Loading from "@/components/Loading";
import { mapGetters } from "vuex";

export default {
  name: "home",
  data() {
    return {
      isLoading: false,
      userInfo: null,
      userBanks: [],
      imgBanks: [
        require("../../assets/Logo_Admin.png"),
        require("../../assets/Logo_Bancolombia.png"),
        require("../../assets/Logo_BBVA.png"),
        require("../../assets/Logo_Santander.png"),
      ],
    };
  },
  components: {
    BContainer,
    Loading,
  },
  async created() {
    this.userInfo = this.$store.getters["login/showUserInfo"];
    if (!this.allBanks) {
      this.isLoading = true;
    } else {
      this.filterUserBanks();
    }
    let validation;
    let verifiedUSer;
    try {
      let userName = this.userInfo;
      const response = await this.$store.dispatch(
        "users/getUserInfo",
        userName
      );
      validation = response.secret_version_id;
      verifiedUSer = response.is_verified;
    } catch (e) {
      swal({
        title: `Ocurrió un error`,
        text: "Por favor, intentar nuevamente.",
        icon: "error",
        dangerMode: true,
      });
    }

    if (!verifiedUSer) {
      this.$router.push({
        path: "/user-password/users",
      });
    } else if (!validation) {
      this.$router.push({
        path: "/user-password/users",
      });
      swal({
        title: "Cuenta sin contraseña para descomprimir archivos",
        text: "Por favor, crear una nueva contraseña de archivos",
        icon: "info",
      })
    } else {
      console.log("Has access");
    }
  },
  computed: {
    ...mapGetters({ allBanks: "banks/showBanks" }),
  },
  watch: {
    allBanks() {
      this.filterUserBanks();
      this.isLoading = false;
    },
  },
  methods: {
    async goToBank(bankName) {
      localStorage.setItem("selectedBank", bankName);
      this.isLoading = true;
      this.$router.push({
        name: "ProcessedFiles",
        params: { bankName: bankName },
      });

      this.isLoading = false;
    },
    filterUserBanks() {
      for (let userBankId of this.userInfo.scopes) {
        this.allBanks.forEach((bank) => {
          if (bank.id == userBankId) {
            bank["img"] = this.selectImageBank(bank.name);
            this.userBanks.push(bank);
          }
        });
      }
    },
    selectImageBank(name) {
      if (name == "administrador") {
        return this.imgBanks[0];
      }
      if (name == "bancolombia") {
        return this.imgBanks[1];
      }
      if (name == "bbva") {
        return this.imgBanks[2];
      }
      if (name == "santander") {
        return this.imgBanks[3];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.gmv-home {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.title {
  display: flex;
  width: 25rem;
  padding: 20px;
  justify-content: space-evenly;
  align-items: center;
  h3 {
    color: black;
  }
}
.grid {
  display: flex;
  width: 1000px;
}
.option-bank {
  margin: 20px 0px;
}
.card-item {
  cursor: pointer;
  &:hover {
    box-shadow: 2px 2px 10px #590808;
  }
}
.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
  height: 7rem;
  width: 25rem;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-evenly;
}
.cards-banks {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bank-card__title {
  display: none;
}
</style>
